<template>
  <v-container>
    <v-row>
      <AvailableMembershipSelection
        :planning="$attrs.value"
        @onAddMembershipClicked="addMembership"
        v-show="canEditMembership"
      />
    </v-row>
    <v-row>
      <v-col>
        <v-container v-show="showCurrentMembership">
          <v-row
            align="center"
            justify="start"
            v-for="item in $attrs['value'].planning_memberships"
            :key="item.id"
            class="mb-4"
            no-gutters
          >
            <v-col class="grow">
              {{ getFullClientName(item.membership.client) }}</v-col
            >
            <v-col class="shrink">
              <!-- <span style="width: 100px;">
                <v-combobox
                  v-model="item.unit"
                  :items="[0, 1, 2, 3, 4, 5]"
                  solo
                  dense
                  small-chips
                  :disabled="!canEditMembership"
                ></v-combobox>
              </span> -->
              <v-btn
                x-small
                color="primary"
                fab
                @click="removePlanningMembership(item)"
                class="ml-2"
                :disabled="!canEditMembership"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AvailableMembershipSelection from "./AvailableMembershipSelection";

export default {
  name: "MembershipSelection",
  components: {
    AvailableMembershipSelection,
  },
  props: {
    lesson: Object,
  },
  data() {
    return {
      loading: true,
      maximumUnit: null,
    };
  },
  computed: {
    showCurrentMembership() {
      //return true;
      if (this.$attrs["value"].planning_memberships == null) return false;

      if (this.$attrs["value"].planning_memberships.length == 0) return false;

      return true;
    },
    canEditMembership() {
      if (!this.planning) return true;

      if (!this.planning.lessonId) return false;

      return !this.planning.canceled;
    },
    planning() {
      return this.$attrs["value"];
    },
  },
  methods: {
    getFullClientName(client) {
      const fullName = (
        (client.firstName ?? "") +
        " " +
        (client.lastName ?? "")
      ).trim();

      return client.companyName
        ? fullName
          ? `${fullName} (${client.companyName})`
          : client.companyName
        : fullName;
    },
    addMembership(membership, unit) {
      const newPlanningMembership = {
        membershipId: membership.id,
        unit: unit,
        membership: JSON.parse(JSON.stringify(membership)),
      };

      this.$attrs["value"].planning_memberships.push(newPlanningMembership);
    },
    removePlanningMembership(planningMembership) {
      const indexPlanning =
        this.$attrs["value"].planning_memberships.indexOf(planningMembership);
      if (indexPlanning >= 0)
        this.$attrs["value"].planning_memberships.splice(indexPlanning, 1);
    },
  },
  mounted() {},
};
</script>